import React, { useEffect, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import useWindowSize from 'styles/window-size'
import MenuButton from './menu-button'
import Button from '../ui-elements/button'
import { ThemeContext } from 'styles/theme-provider'

import {
  Wrap,
  Logo,
  Primary,
  Li,
  Item,
  Submenu,
  SubmenuContent,
  SubmenuUl,
  SubmenuLi,
  SubmenuItem,
  ContactBtnWrap,
} from './index.styled'

const Header = ({ className, ...props }) => {
  const {
    menuContent: { content: links },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          primary {
            text
            url
            submenu {
              text
              url
              type
              hover
            }
          }
        }
      }
    }
  `)
  const location = useLocation()
  const { isMobile } = useWindowSize()
  const primaryRef = React.useRef(null)
  const [submenu, setSubmenu] = React.useState(false)
  const { setResetForm } = useContext(ThemeContext)

  const submenuOpen = () => {
    setSubmenu(true)
  }
  const submenuClose = () => {
    setSubmenu(false)
  }
  const handleClickOutside = React.useCallback((event) => {
    if (primaryRef.current && !primaryRef.current.contains(event.target)) {
      submenuClose()
    }
  }, [])
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <>
      <Wrap className={[className].join(' ')} {...props} id="header">
        <Logo to="/" alt="Williamsburg Wharf logo" />
        {!isMobile && (
          <Primary ref={primaryRef}>
            {links.primary.map((item, index) => (
              <Li
                key={`primary-item-${index}`}
                className={[
                  !!item.submenu && submenu && 'submenu-open',
                  location.pathname === item.url && location.pathname === '/'
                    ? 'active'
                    : item.url !== '/' && location.pathname.includes(item.url)
                    ? 'active'
                    : '',
                ].join(' ')}
                onClick={
                  item.submenu
                    ? () => {
                        submenuOpen(true)
                      }
                    : submenuClose
                }
              >
                {item.submenu ? (
                  <Item as="span" className="with-submenu">
                    {item.text}
                  </Item>
                ) : (
                  <Item to={item.url}>{item.text}</Item>
                )}

                <Submenu>
                  <SubmenuContent>
                    <SubmenuUl>
                      {links.primary[1].submenu?.map((item, i) => (
                        <SubmenuLi key={`submenu-item-${index}-${i}`}>
                          <SubmenuItem
                            to={item.url}
                            className={item.type || ''}
                          >
                            <span className={[item.hover ? 'text' : '']}>
                              {item.text}
                            </span>
                            {item.hover && (
                              <span className="texthover">{item.hover}</span>
                            )}
                          </SubmenuItem>
                        </SubmenuLi>
                      ))}
                    </SubmenuUl>
                  </SubmenuContent>
                </Submenu>
              </Li>
            ))}
          </Primary>
        )}
        {/*  <ContactButtonChat /> */}
        <ContactBtnWrap>
          <Button
            title="INQUIRE"
            to="/contact"
            $inverted
            onClick={() => setResetForm(true)}
          />
        </ContactBtnWrap>
        <MenuButton />
      </Wrap>
    </>
  )
}
export default Header
