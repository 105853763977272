import React from 'react'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'
import { Link } from 'gatsby'

export const Element = styled((props) => <Link {...props} />)`
  font-family: 'GT America Condensed';
  font-size: ${calculateResponsiveSize(24)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(28)};
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  border: 1px solid var(--OWW-blue);
  padding: ${calculateResponsiveSize(6)} ${calculateResponsiveSize(26)};

  transition: background-color 0.5s, color 0.5s;

  background-color: transparent;
  color: var(--OWW-blue);

  &:visited {
    color: var(--OWW-blue);
  }
  &:hover {
    background-color: var(--OWW-blue);
    color: var(--white);
    &:visited {
      color: var(--white);
    }
  }
  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(32)};

    padding-top: ${calculateMobileResponsiveSize(2)};
    padding-left: ${calculateMobileResponsiveSize(13)};
    padding-right: ${calculateMobileResponsiveSize(13)};

    font-size: ${calculateMobileResponsiveSize(14)};
    line-height: ${calculateMobileResponsiveSize(15.4)};
    &.small {
      height: ${calculateMobileResponsiveSize(19)};
      font-size: ${calculateMobileResponsiveSize(12)};
      line-height: ${calculateMobileResponsiveSize(16)};
      padding-left: ${calculateMobileResponsiveSize(7)};
      padding-right: ${calculateMobileResponsiveSize(7)};
    }

    border: 1px solid var(--OWW-blue);
    &:hover {
      background-color: transparent;
      color: var(--OWW-blue);
      &:visited {
        color: var(--OWW-blue);
      }
    }
  }
`
