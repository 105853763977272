import React from 'react'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'
import { Link } from 'gatsby'

const invertColor = (color) => ({ $inverted }) =>
  !$inverted ? color : color === '--blue' ? '--white' : '--blue'

export const Element = styled((props) => <Link {...props} />)`
  font-family: 'Berthold Akzidenz Grotesk';
  font-size: ${calculateResponsiveSize(22)};
  font-style: normal;
  font-weight: 500;
  line-height: ${calculateResponsiveSize(24)};
  text-transform: uppercase;
  height: ${calculateResponsiveSize(44)};

  padding-top: ${calculateResponsiveSize(5)};
  padding-left: ${calculateResponsiveSize(20)};
  padding-right: ${calculateResponsiveSize(20)};
  flex: 0 0 auto;

  cursor: pointer;
  text-decoration: none;

  border: ${calculateResponsiveSize(1)} solid var(${invertColor('--white')});

  transition: background-color 0.5s, color 0.5s;

  background-color: var(${invertColor('--blue')});
  color: var(${invertColor('--white')});
  display: flex;
  align-items: center;
  justify-content: center;

  &:visited {
    color: var(${invertColor('--white')});
  }
  &:hover {
    background-color: var(${invertColor('--white')});
    color: var(${invertColor('--blue')});
  }

  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(24)};

    padding-top: ${calculateMobileResponsiveSize(2)};
    padding-left: ${calculateMobileResponsiveSize(13)};
    padding-right: ${calculateMobileResponsiveSize(13)};

    font-size: ${calculateMobileResponsiveSize(14)};
    line-height: ${calculateMobileResponsiveSize(15.4)};
    border: ${calculateMobileResponsiveSize(1)} solid
      var(${invertColor('--white')});
  }
`
