import React from 'react'
import Button from '../ui-elements/button'
import { ThemeContext } from '../../../styles/theme-provider'

import { Wrap, Logo, OWWLogo, Right, Tip } from './index.styled'

const Header = ({ className, title = '', ...props }) => {
  const { setResetForm } = React.useContext(ThemeContext)

  return (
    <>
      <Wrap className={[className].join(' ')} {...props} id="header">
        <Logo to="/" />
        <Tip>Return to master plan</Tip>

        <OWWLogo to="/oww/" />
        <Right>
          <Button
            to="/oww/contact/"
            title="inquire"
            className="small"
            onClick={() => setResetForm(true)}
          />
        </Right>
      </Wrap>
    </>
  )
}
export default Header
