import React from 'react'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'
import DownloadIcon from 'icons/download.svg'
import DownloadMIcon from 'icons/download-mob.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(120)};
  height: ${calculateResponsiveSize(90)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--blue);
  display: flex;
  &:hover + div {
    opacity: 1;
  }

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(56)};
    height: ${calculateMobileResponsiveSize(42)};
    margin-left: auto;
  }
`

export const Wrap = styled.header`
  display: flex;
  border-bottom: ${calculateResponsiveSize(2)} solid var(--blue);
  align-items: center;
  padding-right: ${calculateResponsiveSize(42)};
  justify-content: space-between;
  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(60)};
    border-bottom: ${calculateMobileResponsiveSize(2)} solid var(--blue);
    padding: 0 ${calculateMobileResponsiveSize(32)};
    justify-content: flex-start;
  }
`
export const Title = styled.h1`
  font-family: 'Berthold Akzidenz Grotesk';
  font-size: ${calculateResponsiveSize(48)};
  font-weight: 500;
  line-height: ${calculateResponsiveSize(52.8)};
  letter-spacing: 0.03em;
  text-align: center;
  color: var(--blue);
  text-transform: uppercase;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-top: ${calculateResponsiveSize(10)};
  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(14)};
    line-height: ${calculateMobileResponsiveSize(15.4)};
    letter-spacing: 0.07em;
    position: static;
    transform: translateX(0%);
    padding-top: ${calculateMobileResponsiveSize(5)};
    order: -1;
    white-space: nowrap;
  }
`

export const Right = styled.div`
  display: flex;
  align-items: center;
  ${mediaMax('mobile')} {
/*     margin-left: -${calculateMobileResponsiveSize(8)};
 */    
margin-left: ${calculateMobileResponsiveSize(20)};

.contact-btn {
      margin-right: ${calculateMobileResponsiveSize(35)};
      margin-left: ${calculateMobileResponsiveSize(12)};
    }
  }
`
export const DownloadButton = styled.a`
  width: ${calculateResponsiveSize(44)};
  height: ${calculateResponsiveSize(44)};
  background: url(${DownloadIcon}) no-repeat;
  border: 1px solid var(--blue);

  background-size: contain;
  /* background: var(--blue); */
  display: flex;
  &:hover + div {
    opacity: 1;
  }

  ${mediaMax('mobile')} {
    background: url(${DownloadMIcon}) no-repeat;

    border: none;
    width: ${calculateMobileResponsiveSize(18.57)};
    height: ${calculateMobileResponsiveSize(20)};
    margin: 0 ${calculateMobileResponsiveSize(23)} 0
      ${calculateMobileResponsiveSize(14)};
  }
`
export const Tip = styled.div`
  opacity: 0;
  order: -1;
  transition: 0.5s;
  font-family: 'GT America Condensed';
  font-size: ${calculateResponsiveSize(18)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  text-align: center;
  color: var(--OWW-blue);
  display: flex;
  align-items: center;
  text-transform: uppercase;

  margin-right: ${calculateResponsiveSize(12)};
  &.home {
    order: 0;
    position: absolute;
    left: ${calculateResponsiveSize(120)};
    margin-right: 0;
  }
  ${mediaMax('mobile')} {
    display: none;
  }
`
export const InquireButton = styled((props) => <Link {...props} />)`
  font-family: 'Berthold Akzidenz Grotesk';
  font-size: ${calculateResponsiveSize(22)};
  font-weight: 500;
  line-height: ${calculateResponsiveSize(24)};
  text-align: center;
  border: 1px solid var(--blue);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${calculateResponsiveSize(44)};
  width: ${calculateResponsiveSize(126)};
  padding-top: ${calculateResponsiveSize(4)};
  margin-left: ${calculateResponsiveSize(48)};
  margin-right: ${calculateResponsiveSize(84)};

  transition: 0.5s;
  &:hover {
    background: var(--blue);
    color: #fff;
  }
  ${mediaMax('mobile')} {
    margin-right: ${calculateMobileResponsiveSize(45)};
    margin-left: -${calculateMobileResponsiveSize(5)};

    font-size: ${calculateMobileResponsiveSize(12)};
    line-height: ${calculateMobileResponsiveSize(120)};

    width: ${calculateMobileResponsiveSize(60)};
    height: ${calculateMobileResponsiveSize(20)};
    padding-top: ${calculateMobileResponsiveSize(2)};
  }
`
