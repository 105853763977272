import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'

export const MenuButtonIcon = styled.button`
  z-index: 102;

  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${calculateResponsiveSize(8)};
  width: ${calculateResponsiveSize(36)};
  position: fixed;
  right: ${calculateResponsiveSize(42)};
  top: ${calculateResponsiveSize(40)};
  &.closeBtn {
  }

  span {
    display: flex;
    width: ${calculateResponsiveSize(36)};
    height: ${calculateResponsiveSize(2)};
    background: var(--blue);
    transition: 0.5s;
    position: relative;
  }
  span:first-child {
    display: flex;
    width: ${calculateResponsiveSize(36)};
    height: ${calculateResponsiveSize(2)};
  }

  .home-wrapper.open-menu & {
    span {
      transform: rotate(45deg);
      top: -${calculateResponsiveSize(5)};
      width: ${calculateResponsiveSize(40)};
      background: #fff;
    }
    span:first-child {
      transform: rotate(-45deg);
      top: ${calculateResponsiveSize(5)};
      width: ${calculateResponsiveSize(40)};
    }
  }

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(24)};
    gap: ${calculateMobileResponsiveSize(7)};
    right: ${calculateMobileResponsiveSize(32)};
    top: ${calculateMobileResponsiveSize(25)};

    span {
      width: ${calculateMobileResponsiveSize(24)};
      height: ${calculateMobileResponsiveSize(2)};
    }
    span:first-child {
      width: ${calculateMobileResponsiveSize(24)};
      height: ${calculateMobileResponsiveSize(2)};
    }

    .home-wrapper.open-menu & {
      span {
        top: -${calculateMobileResponsiveSize(4.5)};
        width: ${calculateMobileResponsiveSize(28)};
      }
      span:first-child {
        top: ${calculateMobileResponsiveSize(4.5)};
        width: ${calculateMobileResponsiveSize(28)};
      }
    }
    .home-wrapper.open-menu.open-submenu & span {
      background: var(--blue);
    }
  }
`
