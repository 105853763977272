import { createGlobalStyle } from 'styled-components'
import { mediaMax, calculateResponsiveSize } from 'styles/media-queries'

const GlobalStyle = createGlobalStyle`
  :root {
    --blue: #0033A0;
    --white: #FFFFFF;
    --dark: #2D2926;

    --Warm-Gray-Tint-2: #EBE8E4;
    --Warm-Gray: #D7D1CA;
    --OWW-blue: #224194;

  }
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
  }
  *::-webkit-scrollbar {
    width: 0;
  }
  body {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: "Berthold Akzidenz Grotesk";
    font-size: ${calculateResponsiveSize(12)};
    font-weight: 700;
    line-height: ${calculateResponsiveSize(18)};
    letter-spacing: 0;

    color: var(--dark);
    background: var(--white);
    min-width: 320px;
    overflow-y: scroll;
    overflow-x: hidden !important;
    ${mediaMax('mobile')} {
      font-size: 12px;
    line-height: 18px;
    }
  }
  body.hidden {
    height: 100dvh !important;
  }
  body.hidden .home-wrapper {
    height: 100dvh;
    overflow: hidden;
  }

  a, a:hover, a:active, a:visited {
    color: var(--blue);
    text-decoration: none;
  }

  @keyframes fadeIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  .home-content {
    will-change: transform;
    &.beige{
      background: var(--Warm-Gray-Tint-2);
    }
    &.beige-reverse{
      background: var(--Warm-Gray);
    }
  }
  .portal {
    position: fixed;
    inset: 0px;
    z-index: 106;
    display: flex;
    justify-content: center;
  }
  .fadeIn {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease;
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .mob-only{
    display: none !important;
  }
  ${mediaMax('mobile')} {
    .web-only{
      display: none !important;
    }
    .mob-only{
      display: flex !important;
    }
  }
`

export default GlobalStyle
