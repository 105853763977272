import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax,
  mediaMin
} from 'styles/media-queries'

import InstagramLogo from 'images/instagram.svg'

export const InstaIcon = styled.a`
  width: ${calculateResponsiveSize(18)};
  height: ${calculateResponsiveSize(18)};
  -webkit-mask: url(${InstagramLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--dark);
  display: flex;

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(14)};
    height: ${calculateMobileResponsiveSize(14)};
  }
`

export const Wrapper = styled.div`
  font-family: 'GT America Condensed';
  font-weight: 300;

  width: 100%;
  background-color: var(--Warm-Gray);
  &.beige-reverse {
    background: var(--Warm-Gray-Tint-2);
  }
  color: var(--dark);
  padding: ${calculateResponsiveSize(30)} ${calculateResponsiveSize(60)}
    ${calculateResponsiveSize(28)} ${calculateResponsiveSize(60)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;

  a,
  a:hover,
  a:active,
  a:visited {
    color: var(--black);
    text-decoration: none;
  }
  .legal-link {
    display: flex;
    gap: ${calculateResponsiveSize(12)};
  }

  ${mediaMax('mobile')} {
    flex-direction: column;
    padding: ${calculateMobileResponsiveSize(24)}
      ${calculateMobileResponsiveSize(30)} ${calculateMobileResponsiveSize(20)}
      ${calculateMobileResponsiveSize(30)};
    gap: ${calculateMobileResponsiveSize(12)};
    .legal-link {
      display: flex;
      gap: ${calculateMobileResponsiveSize(6.32)};
    }
  }

  .desktop-only {
    ${mediaMax('mobile')} {
      display: none;
    }
  }

  .mobile-only {
    ${mediaMin('mobile')} {
      display: none;
    }
  }
`
export const LinksLegal = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(12)};
  ${mediaMax('mobile')} {
    display: flex;
    gap: ${calculateMobileResponsiveSize(6.32)};
  }
`

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  font-size: ${calculateResponsiveSize(16)};
  font-style: normal;
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  letter-spacing: 0;
  text-transform: uppercase;
  width: 35%;
  text-align: left;

  ${mediaMax('mobile')} {
    width: 100%;
    align-content: center;
    text-align: center;
    order: 5;
    font-size: ${calculateMobileResponsiveSize(12)};
    line-height: ${calculateMobileResponsiveSize(16)};
    flex-direction: column;
    gap: ${calculateMobileResponsiveSize(14)};
  }

  .instagram {
    img {
      height: ${calculateResponsiveSize(18)};
    }
  }

  .fair-housing {
    img {
      height: ${calculateResponsiveSize(24)};
    }
  }
`

export const Contacts = styled.div`
  align-content: end;
  font-size: ${calculateResponsiveSize(16)};
  font-style: normal;
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${calculateResponsiveSize(14)};

  .logo {
    height: ${calculateResponsiveSize(24)};
    margin-bottom: ${calculateResponsiveSize(12)};

    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(24)};
      margin-bottom: ${calculateMobileResponsiveSize(10)};
    }
  }

  .instagram {
    display: none;
    ${mediaMax('mobile')} {
      display: flex;
    }
  }

  ${mediaMax('mobile')} {
    width: 100%;
    align-content: center;
    text-align: center;
    order: 1;
    font-size: ${calculateMobileResponsiveSize(12)};
    line-height: ${calculateMobileResponsiveSize(16)};
    /* margin-bottom: ${calculateMobileResponsiveSize(16)}; */
    gap: ${calculateMobileResponsiveSize(12)};
  }
`
export const ContactsBottom = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(12)};
  justify-content: center;
  ${mediaMax('mobile')} {
    gap: ${calculateMobileResponsiveSize(12)};
  }
`

export const TeamsLogo = styled.div`
  font-family: 'GT America Condensed';

  text-transform: uppercase;
  font-size: ${calculateResponsiveSize(16)};
  font-style: normal;
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 35%;
  text-align: right;
  flex-direction: column;

  .naftaligroup {
    height: ${calculateResponsiveSize(16)};
    margin-right: ${calculateResponsiveSize(38)};

    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(12)};
      margin-right: ${calculateMobileResponsiveSize(22)};
    }
  }

  .serhant {
    height: ${calculateResponsiveSize(26)};
    margin-bottom: ${calculateResponsiveSize(0)};

    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(20)};
      margin-bottom: ${calculateMobileResponsiveSize(0)};
      margin-top: ${calculateMobileResponsiveSize(17)};
    }
  }

  .access {
    height: ${calculateResponsiveSize(26)};
    margin-bottom: ${calculateResponsiveSize(0)};

    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(24)};
      margin-bottom: ${calculateMobileResponsiveSize(0)};
    }
  }

  ${mediaMax('mobile')} {
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    order: 4;

    font-size: ${calculateMobileResponsiveSize(12)};
    line-height: ${calculateMobileResponsiveSize(18)};
    & div {
      display: flex;
      align-items: center;
    }
    .sales-address {
      order: -1;
      margin-bottom: ${calculateMobileResponsiveSize(24)};
    }
  }
`
