import React from 'react'
import { MenuButtonIcon } from './index.styled'

const MenuButton = ({ className, layout = '' }) => {
  const onClick = () => {
    const btn = document.querySelector('.home-wrapper')
    btn.classList.toggle('open-menu')
    btn.classList.remove('open-submenu')
  }

  return (
    <MenuButtonIcon onClick={onClick} className={[className, layout].join(' ')}>
      <span />
      <span />
    </MenuButtonIcon>
  )
}

export default MenuButton
