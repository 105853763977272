import React from 'react'
import useWindowSize from 'styles/window-size'
import MenuButton from '../header/menu-button'
import {
  Wrap,
  Logo,
  Title,
  Right,
  DownloadButton,
  Tip,
  InquireButton
} from './index.styled'

const HeaderBlueWhite = ({ className, title = '', ...props }) => {
  const { isMobile } = useWindowSize()

  return (
    <>
      <Wrap className={[className].join(' ')} {...props} id='header'>
        <Logo id='logo' to='/' />
        <Tip className='home'>Return to master plan</Tip>

        <Title>RETAIL & DINING</Title>
        <Right>
          {/* <DownloadButton href="/" /> */}
          {isMobile ? (
            <>
              {/*  <div className={'contact-btn'}>
                <ContactButtonChat />
              </div> */}
              <InquireButton to='/contact'>inquire</InquireButton>

              <MenuButton />
            </>
          ) : (
            <>
              <Tip>DOWNLOAD RETAIL BROCHURE</Tip>
              <InquireButton to='/contact'>inquire</InquireButton>
            </>
          )}
        </Right>
        <MenuButton />
      </Wrap>
    </>
  )
}
export default HeaderBlueWhite
