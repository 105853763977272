import React from 'react'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'
import OWWLogoIcon from 'icons/oww-logo.svg'
import OWWLogoIconM from 'icons/oww-logo-mob.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(120)};
  height: ${calculateResponsiveSize(90)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--OWW-blue);
  display: flex;
  &:hover + div {
    opacity: 1;
  }

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(60)};
    height: ${calculateMobileResponsiveSize(45)};
    margin-left: auto;
  }
`
export const OWWLogo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(650)};
  height: ${calculateResponsiveSize(37)};
  -webkit-mask: url(${OWWLogoIcon}) no-repeat;
  -webkit-mask-size: contain;
  background: #2d2926;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${mediaMax('mobile')} {
    -webkit-mask: url(${OWWLogoIconM}) no-repeat;
    width: ${calculateMobileResponsiveSize(106)};
    height: ${calculateMobileResponsiveSize(40)};
    position: static;
    transform: translateX(0%);
    order: -1;
  }
`

export const Wrap = styled.header`
  display: flex;
  align-items: center;
  padding-right: ${calculateResponsiveSize(42)};
  justify-content: space-between;
  background: var(--Warm-Gray);
  border-bottom: 1px solid var(--Warm-Gray-Tint-2);
  &.beige-reverse {
    background: var(--Warm-Gray-Tint-2);
  }
  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(60)};
    padding: 0 ${calculateMobileResponsiveSize(32)};
  }
`

export const Right = styled.div`
  display: flex;
  margin-right: ${calculateResponsiveSize(78)};
  ${mediaMax('mobile')} {
    margin-right: ${calculateMobileResponsiveSize(50)};
    margin-left: ${calculateMobileResponsiveSize(10)};
  }
`
export const Tip = styled.div`
  opacity: 0;
  transition: 0.5s;
  font-family: 'GT America Condensed';
  font-size: ${calculateResponsiveSize(18)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  text-align: center;
  color: var(--OWW-blue);
  display: flex;
  align-items: center;
  position: absolute;
  left: ${calculateResponsiveSize(120)};
  text-transform: uppercase;
`
