import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'
import InstaIcon from 'images/instagram.svg'
import BackIcon from 'icons/arrow-back-mob.svg'

export const MenuWrap = styled.div`
  height: 100dvh;
  width: ${calculateResponsiveSize(545)};
  position: fixed;
  background: var(--blue);
  color: #fff;
  top: 0;
  right: 0;
  z-index: 20;
  padding: ${calculateResponsiveSize(58)};
  transform: translateX(100%);
  transition: 1s;

  display: flex;
  flex-direction: column;
  ${mediaMax('mobile')} {
    width: 100%;
    padding: 0px;
    height: 100dvh;
    opacity: 0;
    transition: opacity 0.5s 0s, transform 0s 0.5s; //disappering
    &.submenu{
      background: #D7D1CA;

    }

`

export const Item = styled((props) => <Link {...props} />)`
  color: #fff;
  &:hover,
  &:active,
  &:visited {
    color: #fff;
    text-decoration: none;
  }
  font-family: 'Berthold Akzidenz Grotesk';
  font-size: ${calculateResponsiveSize(36)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(39.6)};
  letter-spacing: 0.07em;
  text-align: center;
  text-transform: uppercase;

  ${mediaMax('mobile')} {
  }
`

export const Li = styled.li``
export const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 19;
  visibility: hidden;

  background: #000;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
`

export const Secondary = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: ${calculateResponsiveSize(36)};
`

export const Wrapper = styled.div`
  z-index: 101;
  position: relative;
  .home-wrapper.open-menu & {
    ${MenuWrap}:not(.submenu) {
      transform: translateX(0);
    }
    ${Overlay} {
      opacity: 0;
      visibility: visible;
    }
  }

  ${mediaMax('mobile')} {
    .home-wrapper.open-menu & {
      ${MenuWrap}:not(.submenu) {
        transform: translateX(0);
        opacity: 1;
        transition: opacity 0.5s 0s, transform 0s 0s;
      }
      ${Overlay} {
        display: none;
      }
    }

    .home-wrapper.open-menu.open-submenu & {
      ${MenuWrap}.submenu {
        transform: translateX(0);
        opacity: 1;
        transition: opacity 0.5s 0s, transform 0s 0s;
      }
    }
  }
`

export const Links = styled.div``
export const Bottom = styled.div`
  font-family: 'GT America';
  font-size: ${calculateResponsiveSize(16)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  text-align: center;
  text-transform: uppercase;

  a,
  a:hover,
  a:active,
  a:visited {
    color: #fff;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${calculateResponsiveSize(12)};
  div {
    display: flex;
    gap: ${calculateResponsiveSize(12)};
    align-items: center;
  }

  ${mediaMax('mobile')} {
    font-family: 'Berthold Akzidenz Grotesk';
    font-size: ${calculateMobileResponsiveSize(12)};
    font-weight: 300;
    line-height: ${calculateMobileResponsiveSize(12)};
    text-align: center;
    gap: ${calculateMobileResponsiveSize(18)};
    padding-bottom: ${calculateMobileResponsiveSize(34)};
    div {
      gap: ${calculateMobileResponsiveSize(12)};
    }
  }
`

export const Instagram = styled.a`
  width: ${calculateResponsiveSize(15)};
  height: ${calculateResponsiveSize(15)};
  -webkit-mask: url(${InstaIcon}) no-repeat;
  -webkit-mask-size: contain;
  background: #fff;
  display: flex;
  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(10.5)};
    height: ${calculateMobileResponsiveSize(10.5)};
  }
`
export const BackButton = styled.button`
  background: url(${BackIcon}) no-repeat center 0;
  width: ${calculateMobileResponsiveSize(104)};
  height: ${calculateMobileResponsiveSize(40)};
  font-size: ${calculateMobileResponsiveSize(12)};
  font-weight: 300;
  line-height: ${calculateMobileResponsiveSize(12)};
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto ${calculateMobileResponsiveSize(39)};
  border: none;
  display: flex;
  align-items: flex-end;
  color: var(--blue);
  background-size: ${calculateMobileResponsiveSize(22)}
    ${calculateMobileResponsiveSize(18)};
`
