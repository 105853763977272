import React, { useRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import MenuButton from '../menu-button'
import { ThemeContext } from 'styles/theme-provider'

import {
  Wrapper,
  MenuWrap,
  Li,
  Overlay,
  Item,
  Primary,
  Instagram,
  Bottom,
  Logo
} from './index.styled'

const Menu = () => {
  const {
    owwMenuContent: { content: links }
  } = useStaticQuery(graphql`
    query MenuOwwQuery {
      owwMenuContent {
        content {
          primary {
            text
            url
            type
          }
        }
      }
    }
  `)
  // console.dir(links)
  const menuRef = useRef(null)
  const { scrollSmoother } = React.useContext(ThemeContext)

  const onClose = () => {
    const btn = document.querySelector('.home-wrapper')
    btn.classList.remove('open-menu')
    btn.classList.remove('open-submenu')
    scrollSmoother.current?.paused(false)
  }
  // const { isMobile } = useWindowSize()

  return (
    <>
      <Wrapper id='menu' ref={menuRef} className=''>
        <MenuWrap>
          <Primary>
            {links.primary.map((item, index) => (
              <Li key={`secondary-item-${index}`} onClick={onClose}>
                <Item to={item.url} className={item?.type || ''}>
                  {item.text}
                </Item>
              </Li>
            ))}
          </Primary>
          <Logo to='/' onClick={onClose} />
          <Bottom>
            <div>
              <Link to='/oww/press' onClick={onClose} className='press'>
                Press
              </Link>

              <Instagram
                href='https://www.instagram.com/williamsburgwharf/'
                target='_blank'
              />
            </div>
            <div>
              <Link to='/oww/legal/' onClick={onClose}>
                Legal
              </Link>
              <a href='/fair-housing-notice.pdf' target='_blank'>
                fair housing notice
              </a>
            </div>
            <a
              href='/OneWilliamsburgWharf-Standard-Operating-Procedures.pdf'
              target='_blank'
            >
              standard operating procedures
            </a>
          </Bottom>
        </MenuWrap>

        <Overlay onClick={onClose} />
      </Wrapper>
      <MenuButton />
    </>
  )
}

export default Menu
