import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import {
  Wrapper,
  Links,
  Contacts,
  TeamsLogo,
  Social,
  Left,
  LeftTop,
} from './index.styled'

import Logo from 'images/logo.svg'
import InstagramLogo from 'images/instagram.svg'
import NaftaliGroupLogo from 'images/naftaligroup-logo.svg'
import corcoranLogo from 'images/corcoran-logo.svg'
import AccessLogo from 'images/access-logo.svg'
import FairHousingLogo from 'images/logo-equalhousing.svg'

const FooterRetail = () => {
  const location = useLocation()
  return (
    <Wrapper>
      <Left>
        <LeftTop>
          <a
            href="https://www.instagram.com/williamsburgwharf/"
            target="_blank"
            rel="noreferrer"
            className="instagram desktop-only"
          >
            <img src={InstagramLogo} alt="instagram" />
          </a>
          <Link to="/legal" className="fair-housing desktop-only">
            <img src={FairHousingLogo} alt="fair housing" />
          </Link>
        </LeftTop>
        <Links>
          <Link to="/press/">Press</Link>
          <Link to="/team/">Team</Link>
        </Links>
      </Left>
      <Contacts>
        <img src={Logo} alt="Williamsburg Wharf logo" className="logo" />
        <Links className="last desktop-only">
          <a href="mailto:info@williamsburgwharfleasing.com">
            info@williamsburgwharfleasing.com
          </a>
        </Links>
        <Links className="last desktop-only">
          <Link to="/legal/">Legal</Link>
          <a href="/fair-housing-notice.pdf" target="_blank">
            fair housing notice
          </a>

          <a
            href="/StandardizedOperatingProcedure2022-04-04.pdf"
            target="_blank"
          >
            standard operating procedures
          </a>
        </Links>
      </Contacts>
      <Social>
        <a
          href="https://www.instagram.com/williamsburgwharf/"
          target="_blank"
          rel="noreferrer"
          className="instagram"
        >
          <img
            src={InstagramLogo}
            alt="instagram"
            className="instagram"
            height="100%"
          />
        </a>
        <a
          href="/fair-housing-notice.pdf"
          target="_blank"
          className="fair-housing"
        >
          <img src={FairHousingLogo} alt="fair housing" />
        </a>
      </Social>
      <TeamsLogo>
        <div>
          <a
            href="https://www.naftaligroup.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={NaftaliGroupLogo}
              alt="Naftali Group"
              className="naftaligroup"
            />
          </a>

          <a
            href="https://www.accessindustries.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={AccessLogo} alt="Access" className="access" />
          </a>
        </div>
        <div className="corcoran-line">
          <p>exclusive marketing & leasing agent:</p>
          <a href="/" target="_blank" rel="noreferrer">
            <img src={corcoranLogo} alt="Corcoran" className="corcoran" />
          </a>
        </div>
        <Links className="last mobile-only">
          <a href="mailto:info@williamsburgwharfleasing.com">
            info@williamsburgwharfleasing.com
          </a>
        </Links>
      </TeamsLogo>

      <Links className="last mobile-only">
        <Link to="/legal/">Legal</Link>
        <a href="/fair-housing-notice.pdf" target="_blank">
          fair housing notice
        </a>
        <a href="/StandardizedOperatingProcedure2022-04-04.pdf" target="_blank">
          standard operating procedures
        </a>
      </Links>
    </Wrapper>
  )
}

export default FooterRetail
