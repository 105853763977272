import * as React from 'react'

import GlobalStyle from 'styles/global'

import { PageWrapper, ContentWrapper } from './index.styled'

const BlueThemeHomeWrapper = ({ location, children, LayoutContent }) => {
  return (
    <>
      <PageWrapper>
        <GlobalStyle />
        <ContentWrapper>
          <LayoutContent location={location}>{children}</LayoutContent>
        </ContentWrapper>
      </PageWrapper>
    </>
  )
}

export default BlueThemeHomeWrapper
