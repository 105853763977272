import React from 'react'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'
import ContactIcon from 'icons/contact.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(120)};
  height: ${calculateResponsiveSize(90)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--blue);
  display: flex;
  margin-right: ${calculateResponsiveSize(40)};

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(80)};
    height: ${calculateMobileResponsiveSize(60)};
    margin-right: ${calculateMobileResponsiveSize(0)};
    #menu & {
      background: #fff;
    }
    #menu .submenu & {
      background: var(--blue);
    }
  }
`

export const Wrap = styled.header`
  display: flex;
  border-bottom: ${calculateResponsiveSize(2)} solid var(--blue);
  align-items: center;
  padding-right: ${calculateResponsiveSize(42)};
  ${mediaMax('mobile')} {
    padding-right: ${calculateMobileResponsiveSize(66)};
    border-bottom: ${calculateMobileResponsiveSize(2)} solid var(--blue);
    justify-content: space-between;
  }
`
export const Primary = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-right: ${calculateResponsiveSize(122)};
  color: var(--blue);
  ${mediaMax('mobile')} {
    flex-direction: column;
    flex: none;
    gap: ${calculateMobileResponsiveSize(26)};
    margin: auto;
  }
`
export const Submenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  height: 0px;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: ${calculateResponsiveSize(92)};
  overflow: hidden;
  transition: opacity 0.5s 0s, height 0s 0.5s;
  z-index: 1;
  background: #fff;
  &:after {
    content: '';
    width: 100%;
    height: ${calculateResponsiveSize(2)};
    background: var(--blue);
    position: absolute;
    bottom: 0;
    left: 0;
  }
`
export const SubmenuContent = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
`
export const SubmenuUl = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  left: ${calculateResponsiveSize(420)}; //TODO
  ${mediaMax('mobile')} {
    position: static;
    margin: auto;
    flex: none;
    gap: ${calculateMobileResponsiveSize(26)};
  }
`
export const SubmenuLi = styled.li`
  height: ${calculateResponsiveSize(60)};
  display: flex;
  align-items: center;
  &:before {
    content: '';
    width: 100vw;
    height: ${calculateResponsiveSize(60)};
    border-bottom: ${calculateResponsiveSize(2)} solid var(--blue);
    position: absolute;
    left: -${calculateResponsiveSize(420)}; //TODO
  }
  & .texthover {
    display: none;
  }
  &:hover {
    a {
      color: #fff;
    }
    & .texthover {
      display: block;
    }
    & .text {
      display: none;
    }
    &:before {
      background: var(--blue);
    }
  }
  ${mediaMax('mobile')} {
    height: auto;
    &:before {
      display: none;
    }
    & .texthover {
      display: flex;
      text-transform: uppercase;
      font-size: ${calculateMobileResponsiveSize(20)};
      line-height: ${calculateMobileResponsiveSize(22)};
      letter-spacing: 0.07em;
      font-family: 'Berthold Akzidenz Grotesk';
      font-weight: 300;
      margin-top: 6.582278481012658vw;
    }
    &:hover {
      a {
        color: #2d2926;
      }
      & .text {
        display: block;
      }
    }
  }
`
export const SubmenuItem = styled((props) => <Link {...props} />)`
  font-family: 'Berthold Akzidenz Grotesk';
  font-size: ${calculateResponsiveSize(18)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  color: var(--blue);
  position: relative;
  &.subtitle {
    font-weight: 500;
    text-transform: uppercase;
  }
  ${mediaMax('mobile')} {
    text-transform: uppercase;
    font-size: ${calculateMobileResponsiveSize(20)};
    line-height: ${calculateMobileResponsiveSize(22)};
    letter-spacing: 0.07em;
    text-align: center;
    &,
    &:hover,
    &:active,
    &:visited {
      color: #2d2926;
    }
    &.subtitle {
      font-size: ${calculateMobileResponsiveSize(14)};
      line-height: ${calculateMobileResponsiveSize(15.4)};
      margin-top: ${calculateMobileResponsiveSize(12)};
    }
  }
`

export const Li = styled.li`
  padding: ${calculateResponsiveSize(8)} 0;
  cursor: pointer;
  &.submenu-open {
    ${Submenu} {
      height: ${calculateResponsiveSize(180)}; //TODO
      opacity: 1;
      transition: opacity 0.5s 0s, height 0s 0s;

      cursor: default;
    }
  }
  ${mediaMax('mobile')} {
  }
`
export const Item = styled((props) => <Link {...props} />)`
  font-family: 'Berthold Akzidenz Grotesk';
  font-size: ${calculateResponsiveSize(18)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  text-align: center;
  text-transform: uppercase;
  position: relative;
  &.with-submenu {
  }
  .active & {
    font-weight: 500;
    &:after {
      content: '';
      width: ${calculateResponsiveSize(36)};
      height: ${calculateResponsiveSize(2)};
      background: var(--blue);
      position: absolute;
      bottom: -${calculateResponsiveSize(13)};
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(20)};
    font-weight: 300;
    line-height: ${calculateMobileResponsiveSize(22)};
    letter-spacing: 0.07em;
    text-align: center;
    &,
    &:hover,
    &:active,
    &:visited {
      color: #fff;
    }
  }
`
export const ContactButton = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(28)};
  height: ${calculateResponsiveSize(27)};
  -webkit-mask: url(${ContactIcon}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--blue);
  display: flex;
  margin-right: ${calculateResponsiveSize(80)};

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(28)};
    height: ${calculateMobileResponsiveSize(27)};
    margin-right: ${calculateMobileResponsiveSize(20)};
  }
`
export const ContactBtnWrap = styled.div`
  margin-right: ${calculateResponsiveSize(120)};
`
