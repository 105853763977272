import React from 'react'
import { MenuWrap, BackButton } from './index.styled'
import { Logo, SubmenuUl, SubmenuItem, SubmenuLi } from '../index.styled'

const MenuMobileSubmenu = ({ submenu = [], onClose, onBack = () => {} }) => {
  return (
    <>
      <MenuWrap className='submenu'>
        <Logo to='/' />
        <SubmenuUl>
          {submenu.map((item, i) => (
            <SubmenuLi
              key={`submenu-item-${i}`}
              onClick={item.hover ? () => {} : onClose}
            >
              <SubmenuItem to={item.url} className={item.type || ''}>
                <span className={[item.hover ? 'text' : '']}>{item.text}</span>
                {item.hover && (
                  <span className='texthover'>{item.hover}</span>
                )}
              </SubmenuItem>
            </SubmenuLi>
          ))}
        </SubmenuUl>
        <BackButton type='button' onClick={onBack}>
          Return to menu
        </BackButton>
      </MenuWrap>
    </>
  )
}

export default MenuMobileSubmenu
