import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'gsap-wrapper'
import ThemeProvider from 'styles/theme-provider'
import BlueThemeWrapper from './blue-theme-wrapper'
import BeigeThemeWrapper from './beige-theme-wrapper'
import BlueThemeHomeWrapper from './blue-theme-home-wrapper'
import CookiesBanner from 'components/ui-elements/cookies-banner'
import useWindowSize from '../../styles/window-size'

import 'styles/fonts.css'
import { LayoutWrapper } from './index.styled'

export const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props
  const refWrapper = React.useRef(null)
  const scrollSmoother = React.useRef(null)
  const { isMobile } = useWindowSize()

  React.useLayoutEffect(() => {
    const gsapContext = gsap.context(() => {
      scrollSmoother.current = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 2,
        effects: true,
        normalizeScroll: { allowNestedScroll: true },
      })

      // fadeIn
      gsap.utils.toArray('.fadeIn').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          start: 'top 90%',
          end: '+=1000%',
          toggleClass: {
            targets: container,
            className: 'visible',
            toggle: 'add',
          },
          // once: true,
        })
      })
    }, [refWrapper])

    return () => gsapContext.revert()
  }, [children, isMobile])

  const wrapper_map = {
    beige: BeigeThemeWrapper,
    'beige-reverse': BeigeThemeWrapper,
    'blue-white': BlueThemeWrapper,
    'blue-retails': BlueThemeWrapper,
    undefined: BlueThemeWrapper,
  }

  const Wrapper =
    location.pathname === '/'
      ? BlueThemeHomeWrapper
      : wrapper_map[layout] || BlueThemeWrapper

  // Scroll to anchor
  useEffect(() => {
    if (location.hash) {
      const timeout = setTimeout(() => {
        // console.dir(scrollSmoother.current)
        const hashWithoutHash = location.hash.substring(1)
        const targetElement = document.querySelector(
          `#${hashWithoutHash}-section`,
        )

        if (targetElement) {
          scrollSmoother.current.scrollTo(`#${hashWithoutHash}-section`, false)

          const timeout2 = setTimeout(() => {
            window.scrollBy(0, -2)
          }, 300)
          return () => clearTimeout(timeout2)
        }
      }, 100)
      return () => clearTimeout(timeout)
    }
  }, [location.hash])

  return (
    <ThemeProvider location={location} scrollSmoother={scrollSmoother}>
      <div className="home-wrapper" ref={refWrapper}>
        <CookiesBanner />
        <div className={['home-content', layout].join(' ')}>
          <Wrapper
            layout={layout}
            LayoutContent={LayoutContent}
            location={location}
          >
            {children}
          </Wrapper>
        </div>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
