import React from 'react'
import { Link } from 'gatsby-link'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${calculateResponsiveSize(220)} ${calculateResponsiveSize(0)}
    ${calculateResponsiveSize(220)};
  background: transparent;

  ${mediaMax('mobile')} {
    padding: ${calculateMobileResponsiveSize(64)}
      ${calculateMobileResponsiveSize(32)} ${calculateMobileResponsiveSize(126)};
  }
`
export const Next = styled((props) => <Link {...props} />)`
  font-family: 'New Paris Headline';
  font-size: ${calculateResponsiveSize(72)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(72)};
  letter-spacing: -0.03em;
  text-align: center;

  color: var(--OWW-blue);
  flex-direction: column;
  text-transform: uppercase;
  border-bottom: ${calculateResponsiveSize(1)} solid var(--OWW-blue);
  a,
  a:hover,
  a:active,
  a:visited {
    color: var(--OWW-blue);
  }
  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(40)};
    line-height: ${calculateMobileResponsiveSize(40)};
  }
`
