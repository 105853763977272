import * as React from 'react'
import { HeaderPlaceholder } from 'components/styled-components/home.styled'
import Header from '../header'
import HeaderBlueWhite from '../header-blue-white'
import Footer from 'components/footer'
import FooterRetail from '../footer-retail'
import { ScrollTrigger } from 'gsap-wrapper'
import GlobalStyle from 'styles/global'
import useWindowSize from '../../styles/window-size'

import Menu from '../header/menu'
import { PageWrapper, ContentWrapper } from './index.styled'

const BlueThemeWrapper = ({
  children,
  layout = '',
  LayoutContent,
  location,
}) => {
  const footerRef = React.useRef(null)
  const headerRef = React.useRef(null)
  //const { isMobile } = useWindowSize()
  let tr

  React.useEffect(() => {
    if (headerRef && headerRef.current && footerRef && footerRef.current) {
      tr = ScrollTrigger.create({
        trigger: headerRef.current,
        start: 'top top',
        endTrigger: footerRef.current,
        end: 'bottom top',
        pin: true,
        pinSpacing: false,
      })
    }

    return () => {
      if (tr) {
        tr.kill()
      }
    }
  }, [headerRef, footerRef, layout, children])
  return (
    <>
      <PageWrapper>
        <GlobalStyle />
        <ContentWrapper>
          <LayoutContent location={location}>
            <HeaderPlaceholder ref={headerRef}>
              {layout === 'blue-white' ? <HeaderBlueWhite /> : <Header />}
              <Menu />
            </HeaderPlaceholder>
            {children}
            {layout === 'blue-retails' ? <FooterRetail /> : <Footer />}
            <div ref={footerRef} styles={{ height: '0' }} />
          </LayoutContent>
        </ContentWrapper>
      </PageWrapper>
    </>
  )
}

export default BlueThemeWrapper
