import React from 'react'
import { Link } from 'gatsby'

import {
  Wrapper,
  Links,
  Contacts,
  TeamsLogo,
  ContactsBottom,
  InstaIcon,
  LinksLegal
} from './index.styled'

import Logo from 'images/oww/logo.svg'
// import InstagramLogo from 'images/instagram.svg'
import NaftaliGroupLogo from 'images/oww/naftaligroup-logo.svg'
import serhantLogo from 'images/oww/serhant-logo.svg'
import AccessLogo from 'images/oww/access-logo.svg'

const Footer = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Links>
        <InstaIcon
          href='https://www.instagram.com/williamsburgwharf/'
          target='_blank'
          rel='noreferrer'
          className='instagram desktop-only'
        />
        <LinksLegal>
          <Link to='/oww/legal' className='legal-link'>
            <span>LEGAL</span>
          </Link>

          <a href='/fair-housing-notice.pdf' target='_blank'>
            fair housing notice
          </a>
          <a
            href='/OneWilliamsburgWharf-Standard-Operating-Procedures.pdf'
            target='_blank'
          >
            standard operating procedures
          </a>
        </LinksLegal>
        {/*  <a
          href="/fair-housing-notice.pdf"
          target="_blank"
          className="fair-housing desktop-only"
        >
          <img src={FairHousingLogo} alt="fair housing" />
        </a> */}
      </Links>
      <Contacts>
        <img src={Logo} alt='logo' className='logo' />
        <span>one williamsburg wharf, brooklyn, ny 11249</span>
        <ContactsBottom>
          <a href='mailto:INFO@ONEWILLIAMSBURGWHARF.COM'>
            INFO@ONEWILLIAMSBURGWHARF.COM
          </a>
          <a href='tel:212 482 6084'>212 482 6084</a>
          <InstaIcon
            href='https://www.instagram.com/williamsburgwharf/'
            target='_blank'
            rel='noreferrer'
            className='instagram'
          />
        </ContactsBottom>
      </Contacts>

      <TeamsLogo>
        <div>
          <a
            href='https://www.naftaligroup.com/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={NaftaliGroupLogo}
              alt='Naftali Group'
              className='naftaligroup'
            />
          </a>
          <a
            href='https://www.accessindustries.com/'
            target='_blank'
            rel='noreferrer'
          >
            <img src={AccessLogo} alt='Access' className='access' />
          </a>
        </div>
        <div>
          <a href='https://www.serhant.com/' target='_blank' rel='noreferrer'>
            <img src={serhantLogo} alt='Serhant' className='serhant' />
          </a>
        </div>
        <div className='sales-address'>
          sales gallery: 200 Kent Avenue, 4th Floor, Brooklyn, NY 11249
        </div>
      </TeamsLogo>
    </Wrapper>
  )
}

export default Footer
