import React from 'react'
import { MenuButtonIcon } from './index.styled'
import { ThemeContext } from 'styles/theme-provider'

const MenuButton = ({ className, layout = '' }) => {
  const { scrollSmoother } = React.useContext(ThemeContext)

  const onClick = () => {
    const btn = document.querySelector('.home-wrapper')
    btn.classList.toggle('open-menu')

    const classes = [...btn.classList]
    classes.includes('open-menu')
      ? scrollSmoother.current?.paused(true)
      : scrollSmoother.current?.paused(false)
  }

  return (
    <MenuButtonIcon onClick={onClick} className={[className, layout].join(' ')}>
      <span />
      <span />
    </MenuButtonIcon>
  )
}

export default MenuButton
