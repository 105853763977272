import React, { useRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import MenuButton from '../menu-button'
import useWindowSize from 'styles/window-size'
import MenuMobile from './menu-mobile'

import {
  Wrapper,
  MenuWrap,
  Li,
  Overlay,
  Item,
  Secondary,
  Instagram,
  Bottom
} from './index.styled'

const Menu = () => {
  const {
    menuContent: { content: links }
  } = useStaticQuery(graphql`
    query Menu2Query {
      menuContent {
        content {
          secondary {
            text
            url
          }
        }
      }
    }
  `)
  // console.dir(links)
  const menuRef = useRef(null)

  const onClose = () => {
    const btn = document.querySelector('.home-wrapper')
    btn.classList.remove('open-menu')
    btn.classList.remove('open-submenu')
  }
  const { isMobile } = useWindowSize()

  return (
    <>
      <Wrapper id='menu' ref={menuRef} className=''>
        {isMobile
          ? (
            <MenuMobile onClose={onClose} />
            )
          : (
            <MenuWrap>
              <Secondary>
                {links.secondary.map((item, index) => (
                  <Li key={`secondary-item-${index}`} onClick={onClose}>
                    <Item to={item.url}>{item.text}</Item>
                  </Li>
                ))}
              </Secondary>
              <Bottom>
                <div>
                  <Link to='/legal/' onClick={onClose}>
                    Legal
                  </Link>

                  <Instagram
                    href='https://www.instagram.com/williamsburgwharf/'
                    target='_blank'
                  />
                </div>
                <a href='/fair-housing-notice.pdf' target='_blank'>
                  fair housing notice
                </a>
                <a href='/NY-StandardOperatingProcedures.pdf' target='_blank'>
                  standard operating procedures
                </a>
              </Bottom>
            </MenuWrap>
            )}
        <Overlay onClick={onClose} />
      </Wrapper>
      <MenuButton className='closeBtn' />
    </>
  )
}

export default Menu
