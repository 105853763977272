import React from 'react'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

import { Container, Next } from './index.styled'

const NavigationNext = ({ layout = '' }) => {
  const {
    owwMenuContent: {
      content: { primary }
    }
  } = useStaticQuery(graphql`
    query MenuOwwQuery {
      owwMenuContent {
        content {
          primary {
            text
            url
            type
          }
        }
      }
    }
  `)

  const links = [
    ...primary.filter((item) => item.url !== '/oww/residences/#penthouses')
  ]
  const location = useLocation()

  const index = links.findIndex(
    ({ url }) =>
      location.pathname === url ||
      (url !== '/oww/' && location.pathname.startsWith(url))
  )

  const next =
    index === -1 || index + 1 >= links.length ? links[0] : links[index + 1]

  return (
    <Container className={[layout].join(' ')}>
      <Next to={next.url}>{next.url === '/oww/' ? 'home' : next.text}</Next>
    </Container>
  )
}

export default NavigationNext
