import * as React from 'react'
import { HeaderPlaceholder } from 'components/styled-components/home.styled'
import Header from 'components/oww/header'
import Footer from 'components/oww/footer'
import { ScrollTrigger } from 'gsap-wrapper'
import OWWMenu from '../oww/header/menu'
import GlobalStyle from 'styles/global'
import NavigationNext from '../oww/navigation-next'

import { PageWrapper, ContentWrapper } from './index.styled'

const BeigeThemeWrapper = ({
  preContent = null,
  children,
  location,
  LayoutContent,
  layout
}) => {
  const footerRef = React.useRef(null)
  const headerRef = React.useRef(null)
  let tr

  React.useEffect(() => {
    if (headerRef && headerRef.current && footerRef && footerRef.current) {
      tr = ScrollTrigger.create({
        trigger: headerRef.current,
        start: 'top top',
        endTrigger: footerRef.current,
        end: 'bottom top',
        pin: true,
        pinSpacing: false
      })
    }
    return () => {
      if (tr) {
        tr.kill()
      }
    }
  }, [headerRef, footerRef, children])

  return (
    <>
      <PageWrapper>
        <GlobalStyle />
        <ContentWrapper>
          <LayoutContent location={location}>
            {preContent}
            <HeaderPlaceholder ref={headerRef}>
              <OWWMenu />
              <Header className={layout} />
            </HeaderPlaceholder>
            {children}
            <NavigationNext />
            <Footer className={layout} />
            <div ref={footerRef} styles={{ height: '0' }} />
          </LayoutContent>
        </ContentWrapper>
      </PageWrapper>
    </>
  )
}

export default BeigeThemeWrapper
