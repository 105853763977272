import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax
} from 'styles/media-queries'

export const Wrapper = styled.div`
  width: 100%;
  background-color: var(--blue);
  color: var(--white);
  margin-top: auto;
  padding: ${calculateResponsiveSize(27)} ${calculateResponsiveSize(60)};

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a,
  a:hover,
  a:active,
  a:visited {
    color: var(--white);
    text-decoration: none;
  }
  & .mobile-only {
    display: none;
  }

  ${mediaMax('mobile')} {
    flex-direction: column;
    padding: ${calculateMobileResponsiveSize(24)}
      ${calculateMobileResponsiveSize(26)} ${calculateMobileResponsiveSize(60)}
      ${calculateMobileResponsiveSize(26)};
    & .desktop-only {
      display: none;
    }
    & .mobile-only {
      display: flex;
    }
  }
`

export const Links = styled.div`
  gap: ${calculateResponsiveSize(18)};
  margin-top: auto;
  &.last {
    margin-top: ${calculateResponsiveSize(8)};
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: ${calculateResponsiveSize(14)};
  font-style: normal;
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  letter-spacing: 0;
  text-transform: uppercase;
  color: #fff;

  ${mediaMax('mobile')} {
    justify-content: center;
    font-size: ${calculateMobileResponsiveSize(12)};
    line-height: ${calculateMobileResponsiveSize(12)};

    gap: ${calculateMobileResponsiveSize(12)};
    margin-top: ${calculateMobileResponsiveSize(16)};
    &.last {
      margin-top: ${calculateMobileResponsiveSize(16)};
      order: 6;
      font-size: ${calculateMobileResponsiveSize(10)};
      line-height: ${calculateMobileResponsiveSize(10)};
    }
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  .instagram {
    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(18)};
    }
    img {
      height: ${calculateResponsiveSize(23)};
      ${mediaMax('mobile')} {
        height: ${calculateMobileResponsiveSize(18)};
      }
    }
  }

  .fair-housing {
    img {
      height: ${calculateResponsiveSize(27)};
    }
  }

  ${mediaMax('mobile')} {
    width: 100%;
    order: 5;
    margin-top: ${calculateMobileResponsiveSize(10)};
    align-items: center;
  }
`
export const LeftTop = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(27)};
  align-items: center;
  ${mediaMax('mobile')} {
    display: none;
  }
`

export const Contacts = styled.div`
  align-content: end;
  font-size: ${calculateResponsiveSize(14)};
  font-style: normal;
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  text-transform: uppercase;
  text-align: center;

  .logo {
    height: ${calculateResponsiveSize(24)};
    margin-bottom: ${calculateResponsiveSize(12)};

    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(24)};
      margin-bottom: ${calculateMobileResponsiveSize(0)};
    }
  }

  .instagram {
    height: ${calculateResponsiveSize(12)};
    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(12)};
    }
  }
  & .footer-email {
    margin-top: ${calculateResponsiveSize(6)};
    display: flex;
    justify-content: center;
  }

  ${mediaMax('mobile')} {
    width: 100%;
    align-content: center;
    text-align: center;
    font-size: ${calculateMobileResponsiveSize(14)};
    line-height: ${calculateMobileResponsiveSize(32)};
    margin-bottom: ${calculateMobileResponsiveSize(16)};
    & .footer-email {
      display: none;
    }
  }
`

export const TeamsLogo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
  text-align: right;
  flex-direction: column;

  .naftaligroup {
    height: ${calculateResponsiveSize(16)};
    margin-bottom: ${calculateResponsiveSize(10)};
    margin-right: ${calculateResponsiveSize(60)};

    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(12)};
      margin-right: ${calculateMobileResponsiveSize(18)};
      margin-bottom: ${calculateResponsiveSize(0)};
    }
  }

  .serhant {
    height: ${calculateResponsiveSize(26)};
    margin-bottom: ${calculateResponsiveSize(0)};

    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(20)};
      margin-bottom: ${calculateMobileResponsiveSize(0)};
    }
  }

  .access {
    height: ${calculateResponsiveSize(26)};
    margin-bottom: ${calculateResponsiveSize(0)};

    ${mediaMax('mobile')} {
      height: ${calculateMobileResponsiveSize(24)};
      margin-bottom: ${calculateMobileResponsiveSize(0)};
    }
  }

  ${mediaMax('mobile')} {
    width: 100%;
    align-content: center;
    text-align: center;
    justify-content: center;
    gap: ${calculateMobileResponsiveSize(24)};
    & div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const Social = styled.div`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${mediaMax('mobile')} {
    display: flex;
    margin-bottom: ${calculateMobileResponsiveSize(16)};
    align-content: center;
    text-align: center;
    height: ${calculateMobileResponsiveSize(24)};
    gap: ${calculateMobileResponsiveSize(20)};

    .instagram {
      img {
        height: ${calculateMobileResponsiveSize(16)};
      }
    }

    .fair-housing {
      img {
        height: ${calculateMobileResponsiveSize(24)};
      }
    }
  }
`
